import {
  AdditionalSuccessfulLinkInfo,
  MergeFileStorageData,
  ValidationErrors,
} from "../config/types";
import { InitializeProps, OpenProps, State } from "./types";
import { MERGE_LINK_IFRAME_ID, getIFrame, getMergeLinkURL } from "./utils";
import "./initialize.css";

const state: State = {
  isLinkOpen: false,
  isIFrameCreated: false,
  isIFrameReady: false,
  wasTenantConfigPassedIn: false,
  linkToken: undefined,
  forAdmin: false,
  isForMagicLink: false,
  onValidationError: () => {},
  onExit: () => {},
  onSuccess: () => {},
  onIFrameReady: () => {},
  shouldSendTokenOnSuccessfulLink: false,
  savedBodyStyles: { overflow: "auto", height: "auto" },
  filePickerConfig: undefined,
};

const parseLinkToken = (linkToken: string | undefined | null) => {
  let forAdmin = false;

  if (linkToken && linkToken.includes("?for_admin=")) {
    const linkTokenArray = linkToken.split("?for_admin=");
    linkToken = linkTokenArray[0];
    forAdmin = linkTokenArray[1] == "True" || linkTokenArray[1] == "true";
  }

  return { linkToken, forAdmin };
};

export const getIPAddresses = (apiBaseURL: string) => {
  if (
    apiBaseURL == "https://api.merge.dev/api" ||
    apiBaseURL == "https://api-develop.merge.dev/api"
  ) {
    return [
      "44.194.126.11",
      "44.194.4.0",
      "3.232.227.174",
      "3.214.125.237",
      "54.158.121.71",
      "44.193.163.62",
    ];
  }

  if (apiBaseURL == "https://api-eu.merge.dev/api") {
    return ["13.53.228.73", "13.51.75.140"];
  }

  if (apiBaseURL == "https://api-ap.merge.dev/api") {
    return ["175.41.134.90", "13.250.186.36"];
  }

  if (apiBaseURL == "https://api-oyster-eu.merge.dev/api") {
    return ["15.237.164.133", "13.37.165.83"];
  }
  if (apiBaseURL == "https://api-tripactions-eu.merge.dev/api") {
    return ["18.156.101.109", "3.70.41.50", "3.73.218.193", "3.65.17.64"];
  }

  return [];
};

const getBaseAPIURL = (wasTenantConfigPassedIn: boolean, tenantConfig?: { apiBaseURL: string }) => {
  var baseURL = "";

  if (wasTenantConfigPassedIn) {
    baseURL = tenantConfig?.apiBaseURL ?? "";
  } else {
    switch (process.env.REACT_APP_MERGE_ENV) {
      case "PRODUCTION":
        baseURL = "https://api.merge.dev";
        break;
      case "DEVELOP":
        baseURL = "https://api-develop.merge.dev";
        break;
      case "LOCAL":
      default:
        baseURL = "http://127.0.0.1:8000";
    }
  }

  return baseURL + "/api";
};

export const sendInfoToIFrame = () => {
  const mergeLink = getIFrame();
  if (mergeLink.contentWindow != null && state.linkToken) {
    mergeLink.contentWindow.postMessage(
      {
        messageType: "SEND_INFO_TO_IFRAME",
        linkToken: state.linkToken,
        forAdmin: state.forAdmin,
        apiBaseURL: getBaseAPIURL(state.wasTenantConfigPassedIn, state.tenantConfig),
        isLinkOpen: state.isLinkOpen,
        shouldSendTokenOnSuccessfulLink: state.shouldSendTokenOnSuccessfulLink,
        isForMagicLink: state.isForMagicLink,
        filePickerConfigSent: state.filePickerConfig != null,
        types: state.filePickerConfig?.types,
        allowMultiSelect: state.filePickerConfig?.allowMultiSelect,
        initialPageResponseBody: state.initialPageResponseBody,
      },
      "*"
    );
  }
};

export const createIFrame = (parentContainerID?: string) => {
  const mergeLink = document.createElement("iframe");
  mergeLink.id = MERGE_LINK_IFRAME_ID;
  mergeLink.height = "100%";
  mergeLink.width = "100%";
  mergeLink.style.display = "none";
  mergeLink.style.position = "fixed";
  mergeLink.style.top = "0";
  mergeLink.style.left = "0";
  mergeLink.style.right = "0";
  mergeLink.style.bottom = "0";
  mergeLink.style.zIndex = "9999999999";
  mergeLink.style.borderWidth = "0";
  mergeLink.style.overflowX = "hidden";
  mergeLink.style.overflowY = "auto";
  mergeLink.style.backgroundColor = "transparent";
  mergeLink.src = getMergeLinkURL();

  const parent = parentContainerID != null ? document.getElementById(parentContainerID) : null;
  if (parent !== null) {
    parent.appendChild(mergeLink);
  } else {
    document.body.appendChild(mergeLink);
  }
};

export const initialize = ({
  linkToken: unparsedLinkToken,
  tenantConfig,
  isForMagicLink,
  onValidationError,
  onSuccess,
  onReady,
  organization_organization_id,
  shouldSendTokenOnSuccessfulLink,
  onExit,
  filePickerConfig,
  initialPageResponseBody,
  parentContainerID,
}: InitializeProps) => {
  const { linkToken, forAdmin } = parseLinkToken(unparsedLinkToken);
  const wasInfoUpdated =
    linkToken &&
    (linkToken !== state.linkToken || state.initialPageResponseBody !== initialPageResponseBody);
  state.forAdmin = forAdmin;
  state.linkToken = linkToken ?? undefined;
  state.tenantConfig = tenantConfig;
  state.isForMagicLink = isForMagicLink ?? false;
  state.initialPageResponseBody = initialPageResponseBody;
  state.onValidationError = onValidationError ?? (() => {});
  if (state.tenantConfig) {
    // Failsafe to prevent an incorrect configuration from single-tenant customer.
    // so long as a tenantConfig variable was passed in at least once, prevents accessing any other URL for lifespan of file.
    state.wasTenantConfigPassedIn = true;
  }
  state.onSuccess = onSuccess ?? (() => {});
  state.onIFrameReady = onReady ?? (() => {});
  state.organizationID = organization_organization_id;
  state.shouldSendTokenOnSuccessfulLink = shouldSendTokenOnSuccessfulLink;
  state.filePickerConfig = filePickerConfig;
  state.onExit = onExit ?? (() => {});
  if (state.isIFrameCreated === false) {
    state.isIFrameCreated = true;
    createIFrame(parentContainerID);
  } else {
    if (state.isIFrameReady) {
      state.onIFrameReady();
    }
    if (wasInfoUpdated) {
      sendInfoToIFrame();
    }
  }
};

export const openLink = (config: OpenProps) => {
  state.isLinkOpen = true;
  const mergeLink = getIFrame();

  if (config && config.linkToken) {
    const { linkToken } = parseLinkToken(config.linkToken);
    config.linkToken = linkToken ?? undefined;
  }

  if (config && config.linkToken !== state.linkToken) {
    state.linkToken = config.linkToken ?? undefined;
    state.onSuccess = config.onSuccess;
    state.initialPageResponseBody = config.initialPageResponseBody;
    state.onExit = config.onExit ?? (() => {});
    sendInfoToIFrame();
  } else {
    if (mergeLink.contentWindow != null) {
      mergeLink.contentWindow.postMessage(
        {
          messageType: "SEND_INFO_TO_IFRAME",
          isLinkOpen: state.isLinkOpen,
          initialPageResponseBody: state.initialPageResponseBody,
        },
        "*"
      );
    }
  }

  mergeLink.style.display = "block";

  document.body.classList.add("body-style-open");
};

export const closeLink = () => {
  document.body.classList.remove("body-style-open");

  const mergeLink = getIFrame();
  mergeLink.style.display = "none";
  state.isLinkOpen = false;
  if (mergeLink.contentWindow != null) {
    mergeLink.contentWindow.postMessage(
      {
        messageType: "EXIT_MERGE_LINK",
      },
      "*"
    );
  }
};

window.addEventListener(
  "message",
  (event: {
    data: {
      publicToken: string;
      messageType: string;
      shouldSendTokenOnSuccessfulLink: boolean;
      additionalInfo?: AdditionalSuccessfulLinkInfo | undefined;
      validationErrors?: ValidationErrors;
      selectedFiles?: Array<MergeFileStorageData>;
    };
  }) => {
    const {
      publicToken,
      messageType,
      shouldSendTokenOnSuccessfulLink,
      additionalInfo,
      validationErrors,
      selectedFiles,
    } = event.data;
    switch (messageType) {
      case "SEND_VALIDATION_ERRORS_TO_PARENT":
        if (validationErrors?.length) {
          state.onValidationError(validationErrors);
        }
        return;
      case "SEND_PUBLIC_TOKEN_TO_PARENT":
        if (additionalInfo) {
          state.onSuccess(publicToken, additionalInfo);
        } else {
          state.onSuccess(publicToken);
        }
        if (!shouldSendTokenOnSuccessfulLink) {
          closeLink();
        }
        return;
      case "LINKING_FLOW_READY_FOR_LINK_TOKEN":
        state.isIFrameReady = true;
        state.onIFrameReady();
        sendInfoToIFrame();

        return;
      case "INTEGRATIONS_LOADED":
        state.onIFrameReady();
        return;
      case "CLOSE_LINKING_FLOW":
        closeLink();
        if (state.onExit) {
          state.onExit();
        }
        return;
      case "SEND_SELECTED_FILES_TO_PARENT":
        if (selectedFiles) {
          state.filePickerConfig?.onSubmit(selectedFiles);
        }
        return;
    }
  }
);

window.MergeLink = { initialize, openLink, closeLink };
